/**
 * Create something like MUI theme?
 *
 * See: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createMuiTheme.js
 * See: https://github.com/styled-components/polished
 * See: https://github.com/styled-components/styled-theming
 *
 */

import { rem, lighten, getContrast, darken } from 'polished';

const breakpoints = {
  small: '0px',
  smallmedium: '420px',
  medium: '640px',
  mediumlarge: '820px',
  large: '1024px',
  xlarge: '1280px',
  xxlarge: '1440px',
};

const breakpointClasses = [
  'small',
  'smallmedium',
  'medium',
  'mediumlarge',
  'large',
  'xlarge',
]; // Separate array to be able to define more breakpoints that not currently will be used

function createBreakpoints() {
  const mediaQueries = {};

  // Object.keys(breakpointClasses).forEach((breakpoint) => {
  breakpointClasses.forEach((breakpoint) => {
    mediaQueries[breakpoint] = `(min-width: ${breakpoints[breakpoint]})`;
  });

  return mediaQueries;
}

export const colors = {
  primary: '#2585D9',
  secondary: '#0460D9',
  third: '#044BD9',
  fourth: '#F21D1D',
  success: '#77A617',
  warning: '#F2CE1B',
  alert: '#721111',
  cta: '#F26835',
  alt: '#6987BF',
  black: '#000',
  white: '#ffffff',
  greyLight: '#626873',
  grey: '#363A40',
  greyDark: '#151B26',
};

const buttonColors = {
  primary: colors.primary,
  secondary: colors.secondary,
  third: colors.third,
  success: colors.success,
  warning: colors.warning,
  alert: colors.alert,
  cta: colors.cta,
  alt: colors.alt,
  black: colors.black,
  white: colors.white,
  grey: colors.grey,
};

const theme = {
  mode: 'dark',
  global: {
    radius: '999px',
    menuPadding: '0.6rem 1rem',
    textDirection: 'ltr',
    transition: {
      duration: '0.15s',
      timing: 'ease',
    },
    border: {
      color: colors.grey,
      width: '1px',
      style: 'solid',
    },
  },
  palette: {
    background: {
      body: '#27364A',
      content: '#212d3e',
      accent: colors.cta,
      dark: '#001B40',
      light: colors.greyLight,
    },
    text: {
      primary: colors.white,
      negative: colors.black,
      black: colors.greyDark,
    },
    colors,
    social: {
      facebook: '#3b5998',
      twitter: '#1da1f2',
      pinterest: '#bd081c',
      youtube: '#cd201f',
      instagram: '#e1306c',
      vimeo: '#1ab7ea',
      linkedin: '#0077b5',
      googleplus: '#dd4b39',
    },
    buttons: buttonColors,
  },
  spacing: {
    gutter: rem(20),
    columnGutter: rem(20 / 2),
    // gutter: {
    //   small: rem(20),
    //   large: rem(30),
    // }
  },
  grid: {
    columns: 16,
    maxWidth: rem(1200),
    maxWidthText: rem(820),
    breakpoints: createBreakpoints(),
  },
  typography: {
    global: {
      fontSize: '100%',
      lineHeight: 1.5,
      rendering: 'optimizeLegibility',
    },
    fonts: {
      primary: "'Open Sans', sans-serif",
      heading: "'Noto Sans JP', sans-serif",
      alt: "'Noto Sans JP', sans-serif",
      button: "'Noto Sans JP', sans-serif",
    },
    weights: {
      primary: {
        normal: '400',
        bold: '600',
      },
      alt: {
        normal: '500',
        bold: '700',
      },
      heading: {
        normal: '300',
        bold: '500',
      },
      button: {
        normal: '500',
        bold: '700',
      },
    },
    heading: {
      // lineHeight: 1.32,
      lineHeight: 1.1,
      // marginBottom: '0.7em',
      marginBottom: '0.44em',
      textTransform: 'none',
      headingSizes: {
        small: {
          h1: rem(28),
          h2: rem(28),
          h3: rem(22),
          h4: rem(20),
          h5: rem(18),
          h6: rem(16),
        },
        mediumlarge: {
          h1: rem(30),
          h2: rem(30),
          h3: rem(22),
          h4: rem(20),
          h5: rem(18),
          h6: rem(16),
        },
        large: {
          h1: rem(32),
          h2: rem(32),
          h3: rem(24),
          h4: rem(20),
          h5: rem(18),
          h6: rem(16),
        },
      },
    },
    paragraph: {
      fontSize: rem(17),
      lineHeight: 1.7,
      marginBottom: '1.14rem',
    },
    anchor: {
      default: colors.primary,
      hover: lighten(0.2, colors.primary),
      decoration: 'none',
    },
    button: {
      letterSpacing: '0.01em',
      textTransform: 'uppercase',
      fontWeight: '700',
      buttonSizes: {
        small: rem(13),
        default: rem(16),
        large: rem(18),
      },
    },
  },
  components: {
    button: {
      radius: '6px',
      padding: '0.85rem 2.5em',
    },
    drawer: {
      background: colors.white,
      padding: '0.5rem 1rem',
      item: {
        color: colors.black,
        hover: lighten(0.2, colors.primary),
        padding: '0.6rem 1.15rem',
      },
    },
    menu: {
      item: {
        color: colors.greyDark,
        hover: darken(0.2, colors.white),
        colorNegative: colors.white,
        hoverNegative: darken(0.2, colors.white),
        padding: '0.75rem 0.85rem',
        fontSize: rem(16),
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        fontWeight: '600',
      },
      itemVertical: {
        color: colors.greyDark,
        hover: darken(0.2, colors.white),
        colorNegative: colors.white,
        hoverNegative: darken(0.2, colors.white),
        padding: '0.4em 1.15rem',
        fontSize: rem(16),
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        fontWeight: '600',
      },
    },
  },
};

/**
 * Helper method for easier use of breakpoint output in styled-component styles
 */
export const breakpoint = (size) => {
  return `@media ${theme.grid.breakpoints[size]}`;
};

/**
 * Helper method to generate responsive heading sizing from objects by breakpoint
 */
export const headingSizes = (sizes) => {
  let output = '';

  Object.keys(sizes).forEach((breakpointKey) => {
    let breakpointSizes = '';

    Object.keys(sizes[breakpointKey]).forEach((selector) => {
      breakpointSizes += `
        ${selector} {
          font-size: ${sizes[breakpointKey][selector]};
        }\n`;
    });

    output += `${breakpoint(breakpointKey)} {
      ${breakpointSizes}
    }\n`;
  });

  return output;
};

/**
 * Helper method to generate button variants
 */
export const buttonVariants = (variant) => {
  let output = '';

  // Sizes
  Object.keys(theme.typography.button.buttonSizes).forEach((key) => {
    // skip default size
    if (key !== 'default') {
      output += `
        &.${key} {
          font-size: ${theme.typography.button.buttonSizes[key]};
        }\n`;
    }
  });

  // Colors
  if (variant === 'hollow') {
    Object.keys(buttonColors).forEach((color) => {
      const contrastRatio = getContrast(
        buttonColors[color],
        theme.palette.background.content,
      );
      const contrastRatioHover = getContrast(buttonColors[color], '#fff');

      const textColor = contrastRatio > 2.4 ? '#fff' : '#000';
      const textColorHover = contrastRatioHover > 2.4 ? '#fff' : '#000';

      output += `
        &.${color} {
          color: ${textColor};
          border-color: ${buttonColors[color]};

          &:hover {
            background-color: ${buttonColors[color]};
            color: ${textColorHover};
          }
        }\n`;
    });
  } else {
    Object.keys(buttonColors).forEach((color) => {
      const contrastRatio = getContrast(buttonColors[color], '#fff');
      const textColor = contrastRatio > 2.4 ? '#fff' : '#000';

      output += `
        &.${color} {
          background-color: ${buttonColors[color]};
          color: ${textColor};

          &:hover {
            background-color: ${lighten(0.025, buttonColors[color])};
          }
        }\n`;
    });
  }

  return output;
};

export default theme;
